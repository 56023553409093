import React, { FunctionComponent, useState } from "react";
import styles from "./TableHeader.module.css";
import { IoChevronDown } from "react-icons/io5";
import FilterModal from "../Modals/FilterModal/FilterModal";
import { HeaderGroup } from "react-table";
import classNames from "classnames";
import { useSelector, connect } from "react-redux";
import ApiFilterModal from "../Modals/FilterModal/ApiFilterModal";
import { FaDotCircle } from "react-icons/fa";
import { themeColors } from "../../Components/theme";

type props = {
  headerGroups: HeaderGroup<any>[];
  selectAll?: (check: boolean) => void;
  allCheck?: boolean;
  setAllCheck?: (value: boolean) => void;
  panel:
  | "Organization"
  | "Super admin"
  | "Dynamic header"
  | "User"
  | "Project"
  | "Task"
  | "CallLogs"
  | "LeadDistributor"
  | "API"
  | "NEWS"
  | "DrillDown"
  | "Custom Button"
  | "All User List"
  | "NEWS VIEW";

  theme: any;
  // themeColors:any;
};

type prop = {
  item: any;
  index: number;
  column: any;
  filterOpenIndex: number;
  setShowFiter: (index: number) => void;
  panel:
  | "Organization"
  | "Super admin"
  | "Dynamic header"
  | "User"
  | "Project"
  | "Task"
  | "CallLogs"
  | "LeadDistributor"
  | "API"
  | "NEWS"
  | "DrillDown"
  | "Custom Button"
  | "All User List"
  | "NEWS VIEW";
  filter: any;
  theme: any;
};

const Header: FunctionComponent<prop> = ({
  item,
  index,
  column,
  filterOpenIndex,
  setShowFiter,
  panel,
  filter,
  theme
}) => {
  const role = useSelector((state: any) => state.user.role);
  const [hideAnim, setHideAnim] = useState(false);
  const [showNameTooltip, setShowNameTooltip] = useState(false);
  const iconClass = classNames(
    styles.icon,
    { [styles.iconOpen]: index === filterOpenIndex },
    { [styles.iconClose]: hideAnim === true }
  );
  return (
    <div key={index} onMouseEnter={() => {
      setShowNameTooltip(true)
    }} onMouseLeave={() => {
      setShowNameTooltip(false)
    }}>
      <div className={styles.th}
        style={{ maxWidth: panel === "LeadDistributor" ? showNameTooltip ? "none" : '210px' : showNameTooltip ? "none" : "", minWidth: panel === "LeadDistributor" ? '210px' : '' }}
      >
        <div className={styles.tHeadContainer}>
          {/* <p style={{marginTop:"12px"}}>{item.slice(0,8)}</p> */}
          <th style={{ marginTop: "8px", marginBottom: "8px", color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }}>{showNameTooltip ? item : item.slice(0, 8)}</th>
          <IoChevronDown
            size={18}
            style={{ padding: "none" }}
            color={theme ? themeColors.textPrimary : themeColors.textPrimaryDark}
            className={iconClass}
            onClick={(e) => {
              if (index === filterOpenIndex) {
                setHideAnim(true);
                setShowFiter(-1);
              } else {
                setHideAnim(false);
                setTimeout(() => setShowFiter(index), 100);
              }
            }}
          />
          {localStorage[`${panel + role + index}`] !== "[]" &&
            localStorage[`${panel + role + index}`] && (
              <div className={styles.tooltip}>
                <FaDotCircle
                  size={8}
                  color={"yellow"}
                  style={{ marginBottom: "16px" }}
                />
                <span className={styles.tooltiptext}>
                  Filter
                </span>
              </div>
            )}
        </div>
      </div>
      <div
        style={
          index === filterOpenIndex
            ? { display: "flex" }
            : { display: "none" }
        }
        className={styles.expandFilter}
      >
        {panel === "User" ||
          panel === "Project" ||
          panel === "Task" ||
          panel === "CallLogs" ? (
          <ApiFilterModal
            filterId={panel + role + index}
            column={column}
            expand={index === filterOpenIndex}
            hide={() => {
              setShowFiter(-1);
            }}
            filter={filter.props.column.id}
          />
        ) : (
          <FilterModal
            filterId={panel + role + index}
            column={column}
            expand={index === filterOpenIndex}
            hide={() => {
              setShowFiter(-1);
            }}
            filter={filter.props.column.id}
          />
        )}
      </div>
    </div>
  );
};

const TableHeader: FunctionComponent<props> = ({
  headerGroups,
  selectAll,
  setAllCheck,
  allCheck,
  panel,
  // themeColors,
  theme
}) => {
  const role = useSelector((state: any) => state.user.role);
  const [filterOpenIndex, setFilterOpenIndex] =
    useState(-1);

  const showFiter = (index: number) => {
    setFilterOpenIndex(index);
  };

  const thStyles = {
    backgroundColor: theme ? themeColors.thBackgroundColor : themeColors.thBackgroundColorDark,
  }
  const thTextStyles = {
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark
  }

  return (
    <div
      style={{
        ...thStyles,
        position: "sticky",
        minWidth: "100vw",
        top: 0,
        zIndex: 2,
      }}
    // className="card p-1 shadow rounded align-middle  bg-light sticky-top"
    >
      {headerGroups.map((headerGroup) => (
        <div
          {...headerGroup.getHeaderGroupProps()}
          style={{
            display: "flex",
            flexDirection: "row",
            position: "sticky",
            top: 0,
            zIndex: 2,
            // height:"30px"
          }}
        >
          <div className={styles.sticky} style={{ ...thStyles, ...thTextStyles }}>
            <div className={styles.snoDiv}>
              {panel === "Super admin" || panel === "All User List" || panel === "NEWS VIEW" || (panel === "NEWS" && role === "organization") ? <>
                <th style={{ marginTop: "8px", marginBottom: "8px", marginLeft: "24px", ...thTextStyles }}>Sr. No.</th>
              </> : <>
                <input
                  style={{
                    marginLeft: "16px", marginTop: "10px", marginBottom: "10px"
                    // ,accentColor:"#808080"
                  }}
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setAllCheck && setAllCheck(true);
                      selectAll && selectAll(true);
                    } else {
                      setAllCheck && setAllCheck(false);
                      selectAll && selectAll(false);
                    }
                  }}
                  checked={allCheck}
                />
                {/* <p style={{marginTop:"14px"}}>Sr. No.</p> */}
                <th style={{ marginTop: "8px", marginBottom: "8px", ...thTextStyles }}>Sr. No.</th>
              </>}

            </div>
          </div>
          {headerGroup.headers.map((column, index) => (
            <div
              // className={styles.th}
              {...column.getHeaderProps()}
            // style={{maxWidth:panel==="LeadDistributor"?'210px':'',minWidth:panel==="LeadDistributor"?'210px':''}}
            >
              <Header
                item={column.render("Header")}
                index={index}
                column={column}
                setShowFiter={showFiter}
                filterOpenIndex={filterOpenIndex}
                panel={panel}
                filter={column.render("accessor")}
                theme={theme}
              />
            </div>
          ))}

          {panel === "Super admin" && (
            <div className={styles.thSmall}>
              <p style={{ marginBottom: "8px", marginTop: "8px", fontWeight: "600", ...thTextStyles }}>Deactivate / Activate</p>
            </div>
          )}


          {panel === "Organization" && (
            <div className={styles.thSmall} style={{ fontWeight: "600" }}>
              <p style={{ marginTop: "8px", marginBottom: "8px", ...thTextStyles }}>Inactive/Active</p>
            </div>
          )}

          {panel === "All User List" && (
            <div className={styles.thSmall} style={{ fontWeight: "600" }}>
              <p style={{ marginTop: "8px", marginBottom: "8px", ...thTextStyles }}>Inactive/Active</p>
            </div>
          )}

          {panel === "All User List" && (
            <div className={styles.thSmall} style={{ fontWeight: "600" }}>
              <p style={{ marginTop: "8px", marginBottom: "8px", ...thTextStyles }}>Change Password</p>
            </div>
          )}

          {panel === "All User List" && (
            <div className={styles.thSmall} style={{ fontWeight: "600" }}>
              <p style={{ marginTop: "8px", marginBottom: "8px", ...thTextStyles }}>Delete User</p>
            </div>
          )}

          {panel === "Super admin" && (
            <div className={styles.thSmall}>
              <p style={{ marginBottom: "8px", marginTop: "8px", fontWeight: "600", ...thTextStyles }}>Allow Duplicate Leads</p>
            </div>
          )}

          {panel === "NEWS VIEW" && (
            <div className={styles.thSmall} style={{ fontWeight: "600" }}>
              <p style={{ marginTop: "8px", marginBottom: "8px", ...thTextStyles }}>View News</p>
            </div>
          )}

          {/* {panel === "Organization" && (
            <div className={styles.thSmall}>
              <p>Migrate Permissions</p>
            </div>
          )} */}

          {panel === "Super admin" && (
            <div className={styles.thSmall}>
              <p style={{ marginBottom: "8px", marginTop: "8px", fontWeight: "600", ...thTextStyles }}>Show Analytics</p>
            </div>
          )}

          {panel === "Super admin" && (
            <div className={styles.thSmall} style={{ fontWeight: "600" }}>
              <p style={{ marginTop: "8px", marginBottom: "8px", ...thTextStyles }}>Change Password</p>
            </div>
          )}

          {/* {panel === "LeadDistributor" && (
            <div className={styles.thSmall} style={{ fontWeight: "600" }}>
              <p style={{ marginTop: "8px", marginBottom: "8px", ...thTextStyles }}>Delete</p>
            </div>
          )} */}

          {panel === "Super admin" && (
            <div className={styles.thSmall} style={{ fontWeight: "600" }}>
              <p style={{ marginTop: "8px", marginBottom: "8px", ...thTextStyles }}>Delete Organization</p>
            </div>
          )}
        
          {panel === "Organization" && (
            <div className={styles.thSmall} style={{ fontWeight: "600" }}>
              <p style={{ marginTop: "8px", marginBottom: "8px" }}>Branch</p>
            </div>
          )}
          {/* {panel === "Organization" && (
            <div className={styles.thSmall}>
              <p>Primary Lead Manager</p>
            </div>
          )} */}
          {panel === "Project" && (
            <div className={styles.th}>
              <p></p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(TableHeader);
