import React, { FunctionComponent, useState, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import styles from "../AddOrganization/AddOrganization.module.css";
import { AiOutlineClose } from "react-icons/ai";
import TextInput from "../../Components/TextInput/TextInput";
import DateInput from '../../Components/TextInput/DateInput'
import { emailValidate, nameValidate, phoneValidate, pincodeValidate } from "../../Values/validatorsnew";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { countryData, url } from "../../Values/constants";
import { useEffect } from "react";
import { countActiveUsersByOrganization, editOrganization, updateApiDataEditOrg, updateApiTokensEditOrg, updateContactEditOrg, updateTasksEditOrg, updateUsersAdminEmailEditOrg, updateUsersEditOrg } from "../../Services/organizations";
import { themeColors } from "../../Components/theme";
import Label from "../../Components/Label";
import axios from "axios";
import { showSnackbarAction } from "../../Redux/actions";
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { fetchLicensesCost } from "../../Services/resources";
import { convertTimestampToDate } from "../../Values/utils";

type props = {
  history: any;
  theme: any;
};

const EditOrganization: FunctionComponent<props> = ({ history, theme }) => {
  const dispatcher = useDispatch();
  const location: any = useLocation();
  const [load, setLoad] = useState(false);
  // const [industryList, setIndustryList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [defStatesList, setDefStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  // const [industry, setIndustry] = useState<object>({});
  const [costLicensesData, setCostLicensesData] = useState<any[] | undefined>(undefined);
  const [activeUserCount, setActiveUserCount] = useState<any>();
  const [noEmp, setNoEmp] = useState<any>("");
  const [noEmpError, setNoEmpError] = useState<any>(false);
  const [country, setCountry] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [state, setState] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [cities, setCities] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [phoneCountryCode, setPhoneCountryCode] = useState(`${location.state.detail.admin_contact_code}`);
  const [orgPhoneCountryCode, setOrgPhoneCountryCode] = useState(`${location.state.detail.organization_mobile_code}`);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [inputTextPassword, setInputTextPassword] = useState(false);
  const [finalPhoneNumber, setFinalPhoneNumber] = useState(`${location.state.detail.admin_contact_number}`);
  const [orgFinalPhoneNumber, setOrgFinalPhoneNumber] = useState(`${location.state.detail.mobile_number}`);
  const handlePhoneChange = (value: string, country: CountryData) => {
    // Extract the country code and phone number from the input value
    if (value.startsWith(country?.dialCode)) {
      const phoneNumberWithoutCode = value.substring(country?.dialCode.length);
      setFinalPhoneNumber(phoneNumberWithoutCode);
    } else {
      setFinalPhoneNumber(value);
    }
    setPhoneCountryCode(country?.dialCode);
    setPhoneNumber(value)
  };
  const handleOrgPhoneChange = (value: string, country: CountryData) => {
    // Extract the country code and phone number from the input value
    if (value.startsWith(country?.dialCode)) {
      const phoneNumberWithoutCode = value.substring(country?.dialCode.length);
      setOrgFinalPhoneNumber(phoneNumberWithoutCode);
    } else {
      setOrgFinalPhoneNumber(value);
    }
    setOrgPhoneCountryCode(country?.dialCode);
    setPhoneNumber(value);
  };

  const organizationNameRef: any = useRef();
  const mobileNumberRef: any = useRef();
  const emailRef: any = useRef();
  const cityRef: any = useRef();
  const addressRef: any = useRef();
  const adminFirstNameRef: any = useRef();
  const adminLastNameRef: any = useRef();
  const adminContactNumberRef: any = useRef();
  const adminEmailRef: any = useRef();
  const pincodeRef: any = useRef();

  const employeesRef: any = useRef();
  const passwordRef: any = useRef("");
  const tillDateRef: any = useRef("");

  const licensesCostRef: any = useRef();

  const customStyles = {
    container: (provided: any, state: any) => ({
      ...provided,
      width: "165px", // Set your desired width
    }),
  };

  useEffect(() => {
    console.log("Location All information show :", location.state.detail);
    fetchLicensesCost((data) => setCostLicensesData(data));
    setFinalPhoneNumber(location.state.detail.admin_contact_number);
    // setFinalPhoneNumber(location.state.detail.mobile_number);
  }, []);

  useEffect(() => {
    countActiveUsersByOrganization(
      (data) => setActiveUserCount(data),
      location.state.detail.organization_id
    )
  }, [location.state.detail.organization_id]);

  useEffect(() => {
    setNoEmp(costLicensesData?.[0]?.no_employees);
  }, [costLicensesData]);

  const editOrganizationDetails = (e: any) => {
    e.preventDefault();
    let tillDatevalue = false;
    const currentDate = new Date();
    const targetDate = new Date(tillDateRef.current.value);
    if (currentDate >= targetDate) {
      tillDatevalue = true;
    } else {
      tillDatevalue = false;
    }

    if (country.label === "Select") {
      country.label = location.state.detail.country;
    }
    if (state.label === "Select") {
      state.label = location.state.detail.state;
    }
    if (cities.label === "Select") {
      cities.label = location.state.detail.cities;
    }

    if (organizationNameRef.current.value === "") {
      dispatcher(
        showSnackbarAction(
          "Organization Name Required!!",
          "error"
        )
      );
      return;
    }
    // else if (phoneNumber === "") {
    //   console.log("ashjkdkjasasdSA:",phoneNumber);
    //   dispatcher(
    //     showSnackbarAction(
    //       "Mobile Number Required!!",
    //       "error"
    //     )
    //   );
    //   return;
    // }
    else if (emailRef.current?.value === "") {
      dispatcher(
        showSnackbarAction(
          "Email Required!!",
          "error"
        )
      );
      return;
    }
    else if (addressRef.current.value === "") {
      dispatcher(
        showSnackbarAction(
          "Address Required!!",
          "error"
        )
      );
      return;
    }
    else if (Object.keys(country).length === 0) {
      dispatcher(
        showSnackbarAction(
          "Country Required!!",
          "error"
        )
      );
      return;
    }
    else if (Object.keys(state).length === 0) {
      dispatcher(
        showSnackbarAction(
          "State Required!!",
          "error"
        )
      );
      return;
    }
    else if (Object.keys(cities).length === 0) {
      dispatcher(
        showSnackbarAction(
          "City Required!!",
          "error"
        )
      );
      return;
    }
    else if (pincodeRef.current?.value === "") {
      dispatcher(
        showSnackbarAction(
          "Pincode Required!!",
          "error"
        )
      );
      return;
    }

    else if (adminFirstNameRef.current?.value === "") {
      dispatcher(
        showSnackbarAction(
          "Admin First Name Required!!",
          "error"
        )
      );
      return;
    }
    else if (adminLastNameRef.current?.value === "") {
      dispatcher(
        showSnackbarAction(
          "Admin First Last Required!!",
          "error"
        )
      );
      return;
    }
    else if (finalPhoneNumber === "") {
      dispatcher(
        showSnackbarAction(
          "Mobile Number Required!!",
          "error"
        )
      );
      return;
    }
    else if (orgFinalPhoneNumber === "") {
      dispatcher(
        showSnackbarAction(
          "Mobile Number Required!!",
          "error"
        )
      );
      return;
    }
    else if (adminEmailRef.current?.value === "") {
      dispatcher(
        showSnackbarAction(
          "Admin Email Required!!",
          "error"
        )
      );
      return;
    }
    else if (inputTextPassword === true) {
      if (passwordRef.current.value === "") {
        dispatcher(
          showSnackbarAction(
            "Password is Required!!",
            "error"
          )
        );
        return;
      }
    }
    else if (tillDatevalue === true) {
      dispatcher(
        showSnackbarAction(
          "Till date should be later than the current date!!",
          "error"
        )
      );
      return;
    }
    else if (employeesRef.current.value === "") {
      dispatcher(
        showSnackbarAction(
          "No of Employees Required!!",
          "error"
        )
      );
      return;
    }
    else if (licensesCostRef.current.value === "") {
      dispatcher(
        showSnackbarAction(
          "No of Employees Required!!",
          "error"
        )
      );
      return;
    }
    if (location.state.detail.admin_email_id != adminEmailRef.current?.value) {
      if (inputTextPassword === false) {
        const isConfirmed = window.confirm("Are you sure you want to change Admin Email Id?");
        if (isConfirmed) {
          setInputTextPassword(true);
        }
        else {
          adminEmailRef.current.value = location.state.detail.admin_email_id;
        }
      }
      else {
        setLoad(true);
        updateUserAuth();
        sendResetPasswordEmail();
        //update in apitoken collection
        updateApiTokensEditOrg(
          location.state.detail.organization_id,
          adminEmailRef.current?.value.toLowerCase(),
          adminFirstNameRef.current?.value,
          adminLastNameRef.current?.value,
          orgPhoneCountryCode,
          phoneCountryCode,
          finalPhoneNumber

        );
        //update in users collection
        updateUsersEditOrg(
          location.state.detail.organization_id,
          adminEmailRef.current?.value.toLowerCase(),
          adminFirstNameRef.current?.value,
          adminLastNameRef.current?.value,
          phoneCountryCode,
          finalPhoneNumber,
          employeesRef.current.value,
          licensesCostRef.current.value,
          country.label,
          country.value,
          state.label,
        );
        //update in contact collection
        updateContactEditOrg(
          location.state.detail.admin_email_id,
          location.state.detail.organization_id,
          adminEmailRef.current?.value.toLowerCase()
        );
        //update in apidData collection
        updateApiDataEditOrg(
          location.state.detail.admin_email_id,
          location.state.detail.organization_id,
          adminEmailRef.current?.value.toLowerCase()
        );
        //update in Tasks collection
        updateTasksEditOrg(
          location.state.detail.admin_email_id,
          location.state.detail.organization_id,
          adminEmailRef.current?.value.toLowerCase()
        );
        //edit admil email in userscollection  
        updateUsersAdminEmailEditOrg(
          location.state.detail.admin_email_id,
          location.state.detail.organization_id,
          adminEmailRef.current?.value.toLowerCase()
        );
        const inputDate = new Date(tillDateRef.current.value);
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const tillDateString = `${daysOfWeek[inputDate.getUTCDay()]} ${months[inputDate.getUTCMonth()]} ${inputDate.getUTCDate()} ${inputDate.getUTCFullYear()} ${inputDate.getUTCHours()}:${inputDate.getUTCMinutes()}:${inputDate.getUTCSeconds()} GMT+0530 (India Standard Time)`;
        
        editOrganization(
          location.state.detail.organization_id,
          organizationNameRef.current.value,
          // mobileNumberRef.current.value,
          orgPhoneCountryCode,
          orgFinalPhoneNumber,
          emailRef.current.value,
          addressRef.current.value,
          country.label,
          country.value,
          state.label,
          state.value,
          cities.label,
          cities.value,
          pincodeRef.current.value,
          adminFirstNameRef.current?.value,
          adminLastNameRef.current?.value,
          phoneCountryCode,
          finalPhoneNumber,
          adminEmailRef.current?.value,
          employeesRef.current.value,
          licensesCostRef.current.value,
          location.state.detail.auth_id,
          history,
          dispatcher,
          (data) => setLoad(data),
          tillDateString
        );
        setInputTextPassword(false);
      }
    }
    else {
      setLoad(true);
      updateUsersEditOrg(
        location.state.detail.organization_id,
        adminEmailRef.current?.value.toLowerCase(),
        adminFirstNameRef.current?.value,
        adminLastNameRef.current?.value,
        phoneCountryCode,
        finalPhoneNumber,
        employeesRef.current.value,
        licensesCostRef.current.value,
        country.label,
        country.value,
        state.label,
      );
      const inputDate = new Date(tillDateRef.current.value);
      alert(tillDateRef.current.value);
      const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const tillDateString = `${daysOfWeek[inputDate.getUTCDay()]} ${months[inputDate.getUTCMonth()]} ${inputDate.getUTCDate()} ${inputDate.getUTCFullYear()} ${inputDate.getUTCHours()}:${inputDate.getUTCMinutes()}:${inputDate.getUTCSeconds()} GMT+0530 (India Standard Time)`;
      alert(tillDateString);
      editOrganization(
        location.state.detail.organization_id,
        organizationNameRef.current.value,
        // mobileNumberRef.current.value,
        orgPhoneCountryCode,
        orgFinalPhoneNumber,
        emailRef.current.value,
        addressRef.current.value,
        country.label,
        country.value,
        state.label,
        state.value,
        cities.label,
        cities.value,
        pincodeRef.current.value,
        adminFirstNameRef.current?.value,
        adminLastNameRef.current?.value,
        phoneCountryCode,
        finalPhoneNumber,
        adminEmailRef.current?.value,
        employeesRef.current.value,
        licensesCostRef.current.value,
        location.state.detail.auth_id,
        history,
        dispatcher,
        (data) => setLoad(data),
        tillDateString
      );
    }

  };
  const sendResetPasswordEmail = async () => {
    let email = adminEmailRef.current?.value.toLowerCase()
    let firstName = adminFirstNameRef.current?.value;
    let lastName = adminLastNameRef.current?.value;
    if (email !== "") {
      try {
        if (email) {
          setLoad(true);
          const resetPasswordEmailApiData = {
            "template_name": "Password_Reset_Notification",
            "replaceable_params": [
              { "%#username#%": `${firstName} ${lastName}` },
              { "%#password#%": passwordRef.current?.value }
            ],
            "email": email
          }
          await axios.post(
            url + '/email/sendEmail',
            resetPasswordEmailApiData
          );
          setLoad(false);
          dispatcher(
            showSnackbarAction(
              "Password change information have been sent on you registered email id",
              "success"
            )
          );
          history.push("/");
        }
      } catch (error) {
        console.log("reset error", error);
      }
    } else {
      dispatcher(showSnackbarAction("Please Enter Your Email!", "error"));
    }
  }

  const updateUserAuth = async () => {
    const bodyData = {
      uid: location?.state?.detail?.auth_id,
      newEmail: adminEmailRef.current?.value,
      newPassword: passwordRef.current?.value
    }
    try {
      const repUpdateUserAuth = await axios.post(
        `${url}/utils/firebase-updateUser`, bodyData);
      // handle response
      console.log("repUpdateUserAuth", repUpdateUserAuth);
    } catch (error) {
      // handle error
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const countryRes = await axios.post(
          `${url}/country/countries`);
        // handle response
        setCountryList(countryRes.data);
      } catch (error) {
        // handle error
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (Object.keys(country).length !== 0) {
      const fetchData = async () => {
        try {
          const statesRes = await axios.post(
            `${url}/country/states`, { iso3: (country as any).value });
          setStatesList(statesRes.data);
        } catch (error) {
          // handle error
        }
      };
      fetchData();

    }
  }, [country]);

  useEffect(() => {
    if (location.state.detail.country_code) {
      const fetchData = async () => {
        try {
          const statesRes = await axios.post(
            `${url}/country/states`, { iso3: location.state.detail.country_code });
          setStatesList(statesRes.data);
        } catch (error) {
          // handle error
        }
      };
      fetchData();
    }

  }, [location.state.detail.country_code]);

  useEffect(() => {
    if (location.state.detail.state_code) {
      const fetchData = async () => {
        try {
          const citiesRes = await axios.post(
            `${url}/country/cities`, { iso3: location.state.detail.state_code });
          // handle response
          setCitiesList(citiesRes.data);
        } catch (error) {
          // handle error
        }
      };
      fetchData();
    }

  }, [location.state.detail.state_code]);

  useEffect(() => {
    if (Object.keys(country).length !== 0 && Object.keys(state).length !== 0) {
      const fetchData = async () => {
        try {
          const citiesRes = await axios.post(
            `${url}/country/cities`, { iso3: (country as any).value, state_code: (state as any).value });
          // handle response
          setCitiesList(citiesRes.data);
        } catch (error) {
          // handle error
        }
      };
      fetchData();
    }
  }, [country, state])



  useEffect(() => {
    setCountry({
      label: location.state.detail.country,
      value: location.state.detail.country_code,
    });
    setState({
      label: location.state.detail.state,
      value: location.state.detail.state_code,
    });
    setCities({
      label: location.state.detail.city,
      value: location.state.detail.city,
    });
  }, [countryList]);

  const choice = () => {
    let optionArr = statesList?.map((list: { state_code: any, name: any }) => ({
      value: list.state_code,
      label: list.name
    }));
    // if(optionArr.length === 0){
    //   optionArr = defStatesList?.map((list: { state_code: any, name: any }) => ({
    //     value: list.state_code,
    //     label: list.name
    //   }));
    // }
    return optionArr;
  };

  const countryChoice = () => {
    let optionArr = countryList?.map((list: { iso3: any, name: any, phone_code: any }) => ({
      value: list.iso3,
      label: `${list.name}`
    }));
    return optionArr;
  };

  const citiesChoice = () => {
    let optionArr = citiesList?.map((list: { name: any }) => ({
      value: list.name,
      label: list.name
    }));
    return optionArr;
  };
  const noValidateCost = (LCost: string) => {
    const re = /^[0-9]*$/;
    const valid = re.test(LCost);
    if (valid) {
      // if (Number(empNo) > noEmp) {
      //   setNoEmpError(true);
      //   return `Trial period allows ${noEmp} licences.`
      // }
      // else {
      setNoEmpError(false)
      return "";
      // }
    }
    else {
      return "Allow only Number.";
    }
  };
  const noValidateEmp = (empNo: any) => {
    console.log("activeUserCount", activeUserCount);
    const re = /^[0-9]*$/;
    const valid = re.test(empNo);
    if (valid) {
      if (Number(empNo) < activeUserCount) {
        setNoEmpError(true);
        return `The number of employees can't be less than ${activeUserCount}.`;
      }
      else {
        setNoEmpError(false);
        return "";
      }
    }
    else {
      setNoEmpError(true);
      return "Allow only Number.";
    }
  };

  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const selectInputContainerStyles = { width: "140px", marginRight: "6px" };
  return (
    // <div className={styles.parent}>
    //   {load === true && <Loading />}
    //   <div className={styles.child}>
    //     <div className={styles.headerView}>
    //       <p className={styles.heading}> Edit Organization Details</p>
    //       <AiOutlineClose
    //         className={styles.closeIcon}
    //         size={25}
    //         onClick={() => history.replace("/")}
    //       />
    //     </div>
    //     <div className={styles.title}>
    //       <p className={styles.one}>Organization Name</p>
    //       <p className={styles.two}></p>
    //     </div>
    //     <div className={styles.box}>
    //       <TextInput
    //         title={"Enter Name"}
    //         style={{ backgroundColor: "#fff" }}
    //         ref={organizationNameRef}
    //         value={location.state.detail.organization_name}
    //       ></TextInput>
    //     </div>

    //     <div className={styles.box2}>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Mobile Number</p>
    //           <p className={styles.two}></p>
    //         </div>
    //         <div>
    //           <TextInput
    //             title={"Enter Number"}
    //             style={{ backgroundColor: "#fff" }}
    //             ref={mobileNumberRef}
    //             validator={phoneValidate}
    //             length={true}
    //             value={location.state.detail.mobile_number}
    //           />
    //         </div>
    //       </div>

    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Email Id</p>
    //           <p className={styles.two}></p>
    //         </div>
    //         <div>
    //           <TextInput
    //             title={"Enter Email"}
    //             style={{ backgroundColor: "#fff" }}
    //             ref={emailRef}
    //             validator={emailValidate}
    //             value={location.state.detail.email_id}
    //           />
    //         </div>
    //       </div>
    //     </div>

    //     <div style={{ marginTop: "22px" }} className={styles.title}>
    //       <p className={styles.one}>Address</p>
    //       <p className={styles.two}></p>
    //     </div>
    //     <div className={styles.box}>
    //       <TextInput
    //         title={"Enter Address"}
    //         style={{ backgroundColor: "#fff" }}
    //         ref={addressRef}
    //         value={location.state.detail.address}
    //       />
    //     </div>

    //     <div className={styles.box2}>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>CITY</p>
    //           <p className={styles.two}></p>
    //         </div>
    //         <div>
    //           <TextInput
    //             title={"Enter City"}
    //             style={{ backgroundColor: "#fff" }}
    //             ref={cityRef}
    //             value={location.state.detail.city}
    //           />
    //         </div>
    //       </div>

    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>PINCODE</p>
    //           <p className={styles.two}></p>
    //         </div>
    //         <div>
    //           <TextInput
    //             title={"Enter Pincode"}
    //             style={{ backgroundColor: "#fff" }}
    //             ref={pincodeRef}
    //             value={location.state.detail.pincode}
    //           />
    //         </div>
    //       </div>
    //     </div>

    //     <div className={styles.box2}>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>NUMBER OF EMPLOYEES</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div>
    //           <TextInput
    //             title={"Enter Number Of Employees"}
    //             style={{ backgroundColor: "#fff" }}
    //             ref={employeesRef}
    //             value={location.state.detail.no_of_employees}
    //           />
    //         </div>
    //       </div>
    //     </div>

    //     <button
    //       className={styles.button}
    //       onClick={() => {
    //         editOrganizationDetails();
    //       }}
    //     >
    //       Submit
    //     </button>
    //   </div>
    // </div>
    <div className={styles.parent} style={{ ...containerStyles, marginTop: "4%" }}>
      {load === true && <Loading />}
      <div
        // className={styles.headerView}   
        className="d-flex flex-row w-100 px-4 pt-4">
        <h5
        // className={styles.heading}
        > Edit Organization Details</h5>
         <span style={{color:"red",margin:"5px"}}>(Note : Password fields will be enabled upon submission after updating the admin email.)</span>
        <AiOutlineClose
          className={styles.closeIcon}
          size={25}
          onClick={() => history.replace("/")}
        />
      </div>
      <div
        // className={styles.child}
        className="w-100 d-flex flex-column gap-2 px-4"
      >
        <form className="card p-4 shadow rounded mt-2" style={cardContainerStyles}>
          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label="Organization Name" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={"Enter Name"}
                  style={{ backgroundColor: "#fff", height: "45px", width: "165px" }}
                  ref={organizationNameRef}
                  value={location.state.detail.organization_name}
                ></TextInput>
              </div>
            </div>
            <div className={styles.divide}>
              <Label label="Mobile Number" required={true} />
              <div className={styles.inputContainer}>
                <PhoneInput
                  country={orgPhoneCountryCode}
                  value={`${location.state.detail.organization_mobile_code}+${location.state.detail.mobile_number}`}
                  onChange={handleOrgPhoneChange}
                  inputStyle={{ width: '165px', height: "45px" }}
                  containerStyle={{ height: "45px" }}
                />
                {/* <TextInput
                  title={"Enter Number"}
                  style={{ backgroundColor: "#fff" }}
                  ref={mobileNumberRef}
                  validator={phoneValidate}
                  length={true}
                  value={location.state.detail.mobile_number}
                /> */}
              </div>
            </div>
            <div className={styles.divide}>
              <Label label="Email Id" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={"Enter Email"}
                  // backgroundColor: "#D3D3D3"
                  style={{ height: "45px", width: "165px" }}
                  ref={emailRef}
                  validator={emailValidate}
                  value={location.state.detail.email_id}
                // disabled
                />
              </div>
            </div>
          </div>

          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label="Address" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={"Enter Address"}
                  style={{ backgroundColor: "#fff", height: "45px", width: "165px" }}
                  ref={addressRef}
                  value={location.state.detail.address}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label="Country" required={true} />
              <div style={{ color: "#000", width: "140px", marginRight: "13px" }} >
                <Select
                  options={countryChoice()}
                  value={country}
                  defaultValue={location.state.detail.country}
                  onChange={(data) => {
                    if (data != null) {
                      setCountry(data);
                    }
                  }}
                  styles={customStyles}
                  isSearchable={true}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label="State" required={true} />
              <div style={{ color: "#000", ...selectInputContainerStyles }} >
                <Select
                  options={choice()}
                  value={state}
                  onChange={(data) => {
                    if (data != null) {
                      setState(data);
                    }
                  }}
                  styles={customStyles}
                  isSearchable={true}
                />
              </div>
            </div>
            {/* <div className={styles.divide}>
              <Label label="City" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={"Enter City"}
                  style={{ backgroundColor: "#fff" }}
                  ref={cityRef}
                  value={location.state.detail.city}
                />
              </div>
            </div> */}

            {/* <div className={styles.divide}>
              <Label label="Pincode" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={"Enter Pincode"}
                  style={{ backgroundColor: "#fff" }}
                  ref={pincodeRef}
                  value={location.state.detail.pincode}
                />
              </div>
            </div> */}
          </div>
          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label="City" required={true} />
              <div style={{ ...selectInputContainerStyles }}>
                <Select
                  // className={styles.inputText1}
                  placeholder="City *"
                  value={cities}
                  options={citiesChoice()}
                  onChange={(data) => {
                    if (data != null) {
                      setCities(data);
                    }
                  }}
                  styles={customStyles}
                  isSearchable={true}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label="Pin Code" required={true} />
              <div className={styles.inputContainer} style={{ marginRight: '10px' }}>
                <TextInput
                  title={"Enter Pincode"}
                  style={{ backgroundColor: "#fff", height: "45px", width: "165px" }}
                  ref={pincodeRef}
                  validator={pincodeValidate}
                  value={location.state.detail.pincode}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label="Admin First Name" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={"Enter First Name"}
                  style={{ backgroundColor: "#fff", height: "45px", width: "165px" }}
                  ref={adminFirstNameRef}
                  validator={nameValidate}
                  value={location.state.detail.admin_first_name}
                />
              </div>
            </div>
          </div>

          <div className={styles.box2}>

            <div className={styles.divide}>
              <Label label="Admin Last Name" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={"Enter Last Name"}
                  style={{ backgroundColor: "#fff", height: "45px", width: "165px" }}
                  ref={adminLastNameRef}
                  validator={nameValidate}
                  value={location.state.detail.admin_last_name}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label="Admin Contact No" required={true} />
              <div className={styles.inputContainer}>
                <PhoneInput
                  country={phoneCountryCode}
                  value={`${location.state.detail.admin_contact_code}+${location.state.detail.admin_contact_number}`}
                  onChange={handlePhoneChange}
                  inputStyle={{ width: '165px', height: "45px" }}
                  containerStyle={{ height: "45px" }}
                />
                {/* <TextInput
                  title={"Enter Number"}
                  style={{ backgroundColor: "#fff" }}
                  ref={adminContactNumberRef}
                  validator={phoneValidate}
                  value={location.state.detail.admin_contact_number}
                  length={true}
                /> */}
              </div>
            </div>
            <div className={styles.divide}>
              <Label label="Admin Email Id" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={"Enter Email"}
                  style={{ height: "45px", width: "165px" }}
                  ref={adminEmailRef}
                  validator={emailValidate}
                  value={location.state.detail.admin_email_id}
                // disabled
                />
              </div>
            </div>
          </div>

          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label="Number Of Employees" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={"Enter Number Of Employees"}
                  style={{ height: "45px", width: "165px" }}
                  // style={{ backgroundColor: "#D3D3D3", height: "45px", width: "165px" }}
                  ref={employeesRef}
                  value={location.state.detail.no_of_employees}
                  validator={noValidateEmp}
                // disabled={true}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label="Licenses Cost" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={"Enter Licenses of Cost"}
                  style={{ backgroundColor: "#fff", height: "45px", width: "165px" }}
                  ref={licensesCostRef}
                  value={location.state.detail.cost_per_license}
                  validator={noValidateCost}
                />
              </div>
            </div>
              <div className={styles.divide}>
                <Label label="Password" required={true} />
                <div className={styles.inputContainer}>
                  <TextInput
                    title={"Enter Password"}
                    style={{ backgroundColor:inputTextPassword?"#fff":"#D3D3D3", height: "45px", width: "165px" }}
                    ref={passwordRef}
                    disabled={!inputTextPassword}
                  // value={location.state.detail.no_of_employees}
                  />
                 
                </div>
              </div>
          </div>
          <div className={styles.box2}>
           <div className={styles.divide} style={{width:"33%"}}>
              <Label label="Till Date" required={true} />
              <div className={styles.inputContainer} style={{width:"47%"}}>
                <DateInput
                  title={"Enter Till Date"}
                  style={{ backgroundColor: "#fff", height: "45px"}}
                  ref={tillDateRef}
                  value={location.state.detail.valid_till}
                // validator={noValidateEmp}
                />
              </div>
            </div>
          </div>
          <button
            disabled={noEmpError}
            className={styles.button}
            onClick={(e: any) => {
              editOrganizationDetails(e);
            }}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(EditOrganization);

