import React, { FunctionComponent, useCallback, useState, useEffect } from "react";
import styles from "./PaymentDetails.module.css";
import { themeColors } from "../../Components/theme";
import { connect, useDispatch } from "react-redux";
import { subscriptionDetailsData } from "../../Components/mockData";
import { useHistory } from "react-router";
import useRazorpay from "react-razorpay";
import axios from "axios";
import { useLocation } from "react-router";
import { url } from "../../Values/constants";
import Firebase from "firebase/app";
import { firestore, functions } from "../../Firebase";
import SuccessAndFailureModal from "../../Components/SuccessAndFailureModal/SuccessAndFailureModal";
import { addMonths, getDateString, sendMail, addMonthsWithoutTimestamp, getFormattedDate, addMonthsTP, addMonthsWithoutTimestampTP } from "../../Components/../Values/utils";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { numberWithCommas } from "../../Services/booking";

type props = {
  history: any;
  user: any;
  contacts: {
    data: any;
  };
  organizationUsers: any;
  role: any;
  teamLeadUsers: any;
  filterObject: any;
  filterSort: any;
  refreshContacts: boolean;
  searchString: string;
  theme: any;
  organizationData: any;
  authToken: any;
};

// const PaymentDetails = ({ theme }: any) => {
const PaymentDetails: FunctionComponent<props> = ({
  user,
  contacts,
  organizationUsers,
  role,
  teamLeadUsers,
  filterObject,
  filterSort,
  refreshContacts,
  searchString,
  theme,
  organizationData,
  authToken
}) => {
  const Razorpay = useRazorpay();
  const location: any = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [isTransactionSuccess, setIsTransactionSuccess] = useState(false);
  const options = [
    { value: 1, label: '1 Month' },
    { value: 3, label: '3 Months' },
    { value: 6, label: '6 Months' },
    { value: 12, label: '12 Months' },
  ];
  const [selectedOption, setSelectedOption] = useState<any>({ value: 1, label: '1 Month' });
  const animatedComponents = makeAnimated();
  const [maxHeight, setMaxHeight] = useState(window.innerHeight * 0.8); // Set an initial value, adjust as needed

  const handleResize = () => {
    // Update maxHeight when the window is resized
    setMaxHeight(window.innerHeight * 0.8); // Adjust the multiplier as needed
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let totalLicenses = organizationData && organizationData.no_of_employees;
  let costPerLicense = organizationData && organizationData.cost_per_license;
  let validFrom = organizationData && organizationData.valid_from;
  let validTill = organizationData && organizationData.valid_till;
  let orgName = organizationData && organizationData.organization_name;
  let mailId = organizationData && organizationData.org_mail_id && organizationData.org_mail_id[0];
  let address = organizationData && organizationData.address;
  let contactNumber = organizationData && organizationData.mobile_number;
  let gstNumber = organizationData && organizationData.GSTIN;
  let organization = organizationData && organizationData.organization_name;
  let monthlyAmount = Math.round(totalLicenses * costPerLicense);
  let amount: any = Math.round((totalLicenses * costPerLicense * selectedOption.value));
  let tax: any = Math.round((18 / 100) * amount);
  let totalAmount = Number(amount) + Number(tax);

  let adminMailId = organizationData && organizationData.admin_email_id;
  let isIGST = organizationData && organizationData.is_IGST;

  const updatePlan = (organizationId: any) => {
    let totalMonths = localStorage.getItem('totalMonths') ? Number(localStorage.getItem('totalMonths')) : selectedOption.value;
    let docRef = firestore.collection("organizations").doc(organizationId);
    const newValidTill = organizationData.trial_period ? addMonthsTP(totalMonths) : addMonths(validTill, totalMonths);
    console.log("newValidTill",newValidTill);
    console.log("validFrom",validFrom);

    if (validFrom === undefined || newValidTill === undefined) {
      console.error("One or more values are undefined:", { validFrom, newValidTill });
      return; // Exit the function early
    }
    docRef.update({
      payment_status: true,
      trial_period: false,
      valid_from: validFrom,
      valid_till: newValidTill
    })
      .then(() => {
        console.log("Updated status successfully in Firebase");
        setOpenModal(true);
        setIsTransactionSuccess(true);
      })
      .catch((error) => {
        console.log("Error updating document:", error);
        setOpenModal(true);
        setIsTransactionSuccess(false);
      });
  }

  const handlePayment = useCallback(async (totalAmount: any) => {
    console.log("payment useCallback")
    const mailPaymentSuccessUser = `<div>Hello,</div> <br/><div>Your payment for INR ${totalAmount} was successful and we have renewed your subscription.</div> <br/>  <div>Thanks for using Readpro.</div>  <br/>`;
    const mailPaymentSuccess = `<div>Hello,</div> <br/><div>${orgName} has renewed their subscription.</div> <br/>`;
    const mailPaymentFailureUser = `<div>Hello,</div> <br/><div>Your payment for INR ${totalAmount} has failed and we were not able to renewe your subscription, Please try again.</div> <br/>  <div>Thanks for using Readpro.</div>  <br/>`;
    const mailPaymentFailure = `<div>Hello,</div> <br/><div>${orgName} was trying to renew their subscription but the payment was not successful.</div> <br/> <div>Please reach out to them as soon as possible.</div>  <br/>`;
    axios.defaults.headers.common['x-access-token'] =
      authToken
    const res = await axios.post(url + '/payments/createorder', {
      "amount": totalAmount
    });
    let totalMonths = localStorage.getItem('totalMonths') ? Number(localStorage.getItem('totalMonths')) : selectedOption.value;
    let paymentFrom = new Date().toISOString();
   
    let paymentTill =organizationData.trial_period===true?addMonthsWithoutTimestampTP(totalMonths):addMonthsWithoutTimestamp(validTill, totalMonths);
    let formattedPaymentFrom = getFormattedDate(paymentFrom);
    let formattedPaymentTill = getFormattedDate(paymentTill);
    const { data } = res;
    const options: any = {
      key: "rzp_test_N91p6PGNyIPGjR",
      // key: "rzp_test_PBAMaFcQYsevYS",
      amount: totalAmount,
      currency: "INR",
      name: "Lead Rubix",
      // description: "Test Transaction",
      description: "Transaction",
      // image: "https://www.itsforyou.live/public/uploads/imp/1624874830.png",
      order_id: data.order.id,
      handler: async (res: any) => {
        axios.defaults.headers.common['x-access-token'] =
          authToken
        const resa = await axios.post(url + '/payments/paymentverification',
          {
            uid: user.auth_id,
            contact_no: user.admin_contact_number,
            contact_owner_email: user.user_email,
            organization_id: user.organization_id,
            receipt: data.order.receipt,
            currency: "INR",
            amount: data.order.amount / 100,
            razorpay_order_id: res.razorpay_order_id,
            razorpay_payment_id: res.razorpay_payment_id,
            razorpay_signature: res.razorpay_signature,
            invoice_no: "201906-28",
            company: orgName,
            email: adminMailId,
            phone: contactNumber,
            address: address,
            transaction_date: paymentFrom,
            due_date: paymentTill,
            paymentFrom: paymentFrom,
            paymentTill: paymentTill,
            units: 1,
            igst: isIGST,
            items: [
              {
                serialNumber: 1,
                description: `${totalLicenses} Licenses @ ${costPerLicense}/- each. (From ${formattedPaymentFrom} to ${formattedPaymentTill})`,
                qty: 1,
                hsn: 997331,
                rate: totalAmount,
                taxAmount: Number(tax),
              },
            ]
          }
        );
        if (resa.data.success) {
          console.log("payment success fully")
          updatePlan(user.organization_id);
          // sendMail(mailPaymentSuccessUser,mailId,"Payment Successful");
          // sendMail(mailPaymentSuccess,["hr@notegtechnologies.com","anujchauhan@notegtechnologies.com"],"Payment Successful");
        } else {
          setOpenModal(true);
          setIsTransactionSuccess(false);
          // sendMail(mailPaymentFailureUser,mailId,"Payment Failed");
          // sendMail(mailPaymentFailure,["hr@notegtechnologies.com","anujchauhan@notegtechnologies.com"],"Payment Failed");
        }

      },
      prefill: {
        name: orgName,
        email: mailId,
        contact: contactNumber,
      },
      notes: {
        address: address,
      },
      // theme: {
      //   color: "#3399cc",
      // },
    };

    const rzpay = new Razorpay(options);

    rzpay.on("payment.failed", (err: any) => {
      setOpenModal(true);
      setIsTransactionSuccess(false);
      sendMail(mailPaymentFailureUser,mailId,"Payment Failed");
      sendMail(mailPaymentFailure,["hr@notegtechnologies.com","anujchauhan@notegtechnologies.com"],"Payment Failed");
    });

    rzpay.open();
  }, [Razorpay]);
  const history = useHistory();
  const containerStyles = {
    backgroundColor: theme
      ? themeColors.backgroundColor
      : themeColors.backgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };
  const cardContainerStyles = {
    backgroundColor: theme
      ? themeColors.cardBackgroundColor
      : themeColors.cardBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };
  const tdStyles = {
    backgroundColor: theme
      ? themeColors.tdBackgroundColor
      : themeColors.tdBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
    padding: "1rem",
  };
  const trBorderStyles = {
    borderBottomColor: theme
      ? `1px solid ${themeColors.trBorderColor}`
      : `1px solid ${themeColors.trBorderColorDark}`,
  };
  const thStyles = {
    backgroundColor: theme
      ? themeColors.thBackgroundColor
      : themeColors.thBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
    padding: "1rem",
  };
  const selectInputContainerStyles = { width: "140px", marginRight: "5px" };

  useEffect(() => {
    localStorage.setItem("totalMonths", selectedOption.value);
  }, [selectedOption])

  return (
    <div className={styles.parent} style={{...containerStyles,marginTop:"4%"}}>
      {openModal &&
        (
          <SuccessAndFailureModal
            isSuccess={isTransactionSuccess}
            open={openModal}
            close={() => setOpenModal(false)}
          />)
      }
      <div className={styles.container} style={{
         maxHeight: `${maxHeight}px`,
        overflowX:"hidden",
        overflowY:"scroll",
        marginBottom:"20px"}}>
        <div className="m-auto d-flex flex-column align-items-center mt-4">
          <h5 className="fw-bold">Manage your Leads Rubix Subscription</h5>
          <h6 className="mt-2">Payment Details</h6>
          <div
            className="card p-4 shadow rounded mt-4"
            style={{ maxWidth: 400, minWidth: 400, ...cardContainerStyles }}
          >
            <h6 className="text-center">Billing Details</h6>
            <h6 className="mt-2">
              Organization: {organization}
            </h6>
            <h6>Address: {address}</h6>
            <h6>Phone : {contactNumber}</h6>
            <h6>GSTIN : {gstNumber}</h6>
          </div>
          <div className="w-100 mt-4">
            <table
              className="table shadow rounded bg-white"
              style={{ backgroundColor: "red" }}
            >
              <thead className="bg-light">
                <tr>
                  <th style={thStyles}>Item</th>
                  <th style={thStyles}>Duration</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <td style={tdStyles}>
                    <p className="fw-bold mb-1">Leads Rubix License</p>
                    <p>₹ {numberWithCommas(monthlyAmount)} / month <br /> (At ₹{numberWithCommas(costPerLicense)} for {totalLicenses} Licenses)</p>
                  </td>
                  <td style={tdStyles}>
                    <div style={{ ...selectInputContainerStyles }}>
                      <Select
                        className={styles.select}
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={options}
                        menuPlacement="auto"
                        maxMenuHeight={150}
                        // options={options}
                        value={selectedOption}
                        components={animatedComponents}
                        // onChange={(data) => {
                        //   setSelectedOption(data);
                        // }}
                        showCheckbox={true}
                        isSearchable={true}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h6 className="mt-5">Amount to be paid now</h6>
          <h1>₹ {numberWithCommas(totalAmount)}</h1>
          <h6>₹ {numberWithCommas(amount)} + {numberWithCommas(tax)}(18% Tax)</h6>
          <div className="mt-4">
            <button
              className={styles.buttonSecondary}
              onClick={() => {
                history.push("/subscriptionDetails");
              }}
            >
              Cancel
            </button>
            <button
              className={styles.button}
              style={{ marginLeft: "1rem" }}
              onClick={() => { handlePayment(totalAmount) }}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
      <div
        className="card px-4 pt-2 w-100 d-flex flex-row justify-content-between position-absolute bottom-0 end-0"
        style={cardContainerStyles}
      >
        <div>
          {/* <h6>accounts@itsforyou.live</h6> */}
          <p>(For payments and billing questions)</p>
        </div>
        <div>
          {/* <h6>8755964699</h6> */}
          <p>(Toll Free 24 hours - Monday to Friday)</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    role: state.user.role,
    contacts: state.contacts,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
    filterObject: state.filter,
    filterSort: state.filterSort,
    refreshContacts: state.refresh.refreshContacts,
    searchString: state.searchItem.contactsSearchString,
    theme: state.theme.isLightMode,
    organizationData: state.organization.organizationData,
    authToken: state.user.authToken
  };
};

export default connect(mapStateToProps)(PaymentDetails);
