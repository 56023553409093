import React, { useEffect, useState } from "react";
import styles from "./TextInput.module.css";
import { convertTimestampToDate } from "../../Values/utils";

type Props = {
  title: string;
  children?: any;
  style?: any;
  validator?: (value: string) => string;
  length?: boolean;
  disabled?: boolean;
  value?: { seconds: number; nanoseconds: number };
};

const DateTimeInput = React.forwardRef(
  (
    { title, style, validator, length, disabled, value }: Props,
    ref: any
  ) => {
    const [errorMsg, setErrorMsg] = useState("");
    const [val, setVal] = useState("");

    useEffect(() => {
      if (value) {
        let timestampToDate = convertTimestampToDate(value);
        const originalDateTime = new Date(timestampToDate);
        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: 'numeric',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        };
        const formattedDateTimeString = originalDateTime.toLocaleString('en-US', options);
        // Convert the input string to a Date object
        const inputDate = new Date(formattedDateTimeString);
        // Get date components
        const year = inputDate.getFullYear();
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
        const day = inputDate.getDate().toString().padStart(2, '0');
        const hours = inputDate.getHours().toString().padStart(2, '0');
        const minutes = inputDate.getMinutes().toString().padStart(2, '0');
        // Create the formatted date string
        const formattedDateString = `${year}-${month}-${day}T${hours}:${minutes}`;
        // Print the result
        console.log("Converted date:", formattedDateString);
        // console.log("formattedDateTimeString", formattedDateTimeString);
        setVal(formattedDateString);
      }
    }, [value]);

    // Get the current date and format it for the "min" attribute
    const currentDate = new Date();
    const formattedCurrentDate = currentDate
      .toISOString()
      .slice(0, 16);
      console.log("val for date forate",val);
    return (
      <>
        <div className={styles.input}>
          <input
            style={{ ...style, fontSize: "14px" }}
            className="form-control"
            type="datetime-local" // Use datetime-local input type
            name="name"
            placeholder={title}
            ref={ref}
            onBlur={(event) => {
              if (validator) {
                setErrorMsg(validator(ref.current.value));
              }
            }}
            maxLength={length === true ? 16 : undefined}
            readOnly={disabled}
            onChange={(e) => setVal(e.target.value)}
            value={val}
            min={formattedCurrentDate} // Set the min attribute
          />
        </div>
        {errorMsg.length !== 0 && <p className={styles.error}>{errorMsg}</p>}
      </>
    );
  }
);

export default DateTimeInput;
